import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import Login from "@/views/Login.vue";

import DashboardLayout from "@/layouts/Dashboard.vue";

import HOME_ROUTES from "@/router/home.routes";
import ADMIN_ROUTES from "@/router/admin.routes";
import DOCTOR_ROUTES from "@/router/doctor.routes";
import PATIENT_ROUTES from "@/router/patient.routes";
import SPECIALTY_ROUTES from "@/router/specialty.routes";
import SCHEDULE_ROUTES from "@/router/schedule.routes";
import SETTINGS_ROUTES from "@/router/settings.routes";
import TERM_ROUTES from "@/router/term.routes";
import DASHBOARD_ROUTES from "@/router/dashboard.routes";
import VALUES_ROUTES from "@/router/values.routes";
import DISCOUNT_ROUTES from "@/router/discount.routes";
import PUSH_NOTIFICATION_ROUTES from "@/router/push-notifications.routes";
import SUBSCRIPTIONS_ROUTES from "@/router/subscriptions.routes";
import GETTING_STARTED from "@/router/getting-started.routes";
import SUBSCRIPTION_PLANS_ROUTES from "@/router/subscription-plans.routes";

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }

  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }

  next("/login");
};

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/",
    component: DashboardLayout,
    beforeEnter: ifAuthenticated,
    children: [
      HOME_ROUTES,
      ADMIN_ROUTES,
      DOCTOR_ROUTES,
      PATIENT_ROUTES,
      SPECIALTY_ROUTES,
      SCHEDULE_ROUTES,
      SETTINGS_ROUTES,
      TERM_ROUTES,
      DASHBOARD_ROUTES,
      VALUES_ROUTES,
      DISCOUNT_ROUTES,
      PUSH_NOTIFICATION_ROUTES,
      SUBSCRIPTIONS_ROUTES,
      GETTING_STARTED,
      SUBSCRIPTION_PLANS_ROUTES,
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
