export default [
  // {
  //   icon: "home",
  //   label: "DASHBOARD",
  //   routeName: "dashboard",
  // },
  //Deixar comentado
  {
    icon: "coins",
    label: "VALORES",
    routeName: "values.index",
  },

  {
    icon: "calendar",
    label: "ATENDIMENTOS",
    routeName: "schedules.index",
  },

  {
    icon: "user-cog",
    label: "ADMINISTRADORES",
    routeName: "admins.index",
  },
  {
    icon: "user-tie",
    label: "ADVOGADOS",
    routeName: "doctors.index",
  },
  {
    icon: "user",
    label: "CLIENTES",
    routeName: "patients.index",
  },
  {
    icon: "balance-scale",
    label: "ESPECIALIDADES",
    routeName: "specialties.index",
  },
  {
    icon: "percent",
    label: "Descontos",
    routeName: "discount.index",
  },
  {
    icon: "paper-plane",
    label: "ENVIAR NOTIFICAÇÕES",
    routeName: "send-push-notifications.index",
  },
  {
    icon: "file-alt",
    label: "TERMOS DE USO",
    routeName: "terms.index",
  },
  {
    icon: "signature",
    label: "ASSINATURAS",
    routeName: "subscriptions.index",
  },
  {
    icon: "cog",
    label: "CONFIGURAÇÕES",
    routeName: "settings.index",
  },
];
